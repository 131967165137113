body {
  background-color: #eee;
}

.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0px;
}

.main-content {
  margin: 0 auto;
}

.main-video {
  text-align: center;
}

.video-metadata {
  margin: 1em auto;
}

.video-container {
  max-width: 600px;
  margin: 0 auto;
}

/* .logo {
  margin: 1em auto;
} */

.logo {
  position: relative;
  perspective: 1000px;
  height: 100px;
  width: 400px;
  overflow: hidden;
}

.logo-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100px;
  transition: transform 0.5s;
  font: bold 80px sans-serif;
}

.logo-text--back {
  color: #cd0dca;
  z-index: 1;
  opacity: 0.7;
}

.logo-text--front {
  color: #ab9c9c;
  z-index: 2;
  opacity: 0.8;
}